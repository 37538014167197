#navbar {
  border-radius: 45px;
  width: 100%;
  padding: 2px 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 2px 15px;
  }
  
  & > svg {
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }

  &.changeNavMode {
    background: #00bf6d;
  }

  .navbar__lists {
    margin-left: auto;
    flex: 1;
    margin-right: 25px;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      display: none;
    }

    ul {
      display: flex;
      gap: 32px;

      li {
        list-style-type: none;
      }
      // changed the a tag to the react router Link tag instead
      li a {
        text-decoration: none;
        font-size: 16px;
        line-height: 23px;
        color: #233748;

        &:hover {
          color: #00bf6d;
        }
      }

      .navList {
        transition: transform 0.5s;
        color: #00bf6d;

        .smallCircle {
          width: 10px;
          height: 10px;
          background: #00bf6d;
          border-radius: 99%;
          margin-inline: auto;
          margin-top: 10px;
          // display: none;
          opacity: 0;
          transition: transform 0.5s;
        }

        .smallCircleVar {
          background: white;
        }
      }

      .navList:hover {
        transform: translateY(-3px);

        .smallCircle {
          // display: block;
          opacity: 1;
        }
      }
    }
  }

  .navbar__drawer {
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
      margin-left: auto;
    }
  }
}
