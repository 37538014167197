.about-details__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
  font-family: 'Questrial', sans-serif;
}

.about-details__image-con {
  max-width: 1440px;
  width: 90%;
  margin: auto;
  border-radius: 5px;
  & > img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.about-details__text-con {
  width: 80%;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 1024px) {
    width: 85%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  & > p {
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}
