.kitchen-cards__wrapper {
  background: #ffffff;
  color: #233748;
  position: relative;
  max-width: 1440px;
  margin: 4em auto 0;
  @media (max-width: 768px) {
    margin: 2em auto 0;
  }
}

.kitchen-cards__container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
}

.cards-card__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards-card__image-con {
  display: flex;
  gap: 10px;
  min-height: 55px;
}

.cards-card__text-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h4 {
    font-size: 24px;
  }
  & > p {
    font-size: 16px;
    line-height: 140%
  }
}
