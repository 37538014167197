#buttons{
    // border: 1px solid red;

    // removed the buttons on tablets too
    @media screen and (max-width: 768px){
        margin-bottom: 25px;
        display: none;
    }

    img{
        cursor: pointer;
    }

    img:first-child{
        margin-right: 20px;

    }

   

    &.button > img:first-child{
        margin-right: 0px;
    }
    
    @media screen and (max-width: 1200px) {
        // margin-left: 0;
    }

    @media screen and (max-width: 500px){
        img:first-child{
            margin-right: 0px;
            // margin-bottom: 15px;

            &.marginBottomRemoveOnMobile{
                margin-bottom: 0px;
            }

        }

        text-align: center;
    }
}