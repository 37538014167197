// USER REPORT BUTTON
.userReport__reason{
    // border: 2px dashed red;
    padding: 2px 10px;
    // background: #FDE8E8;
    border-radius: 5.15426px;
    border: none;
    
    &.Spam{
        background: #F3F4F6;
        color: #111928;
    }

    &.Violence, 
    &.ChildAbuse{
        background: #FDE8E8;
        color:#9B1C1C;
    }

    &.Copyright{
        background: #E1EFFE;
        color:#1E429F;
    }
    
    &.Others{
        background: #FDF6B2;
        color: #723B13;
    }

    &.Pornography{
        background: #EDEBFE;
        color: #5521B5;
    }
}