.about-hero__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 7.5em 0 0;
  font-family: 'Gilroy-Bold', sans-serif;
}

.about-hero__text-con {
  width: 70%;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 85%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
  & > h3 {
    font-size: 54px;
    color: #00bf6d;
    @media (max-width: 1280px) {
      font-size: 48px;
    }
    @media (max-width: 1024px) {
      font-size: 44px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      & > br {
        display: none;
      }
    }
    @media (max-width: 576px) {
      font-size: 36px;
    }
  }
}
