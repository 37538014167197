.kitchen-centralized__wrapper {
  background: #ffffff;
  color: #233748;
  position: relative;
  max-width: 1440px;
  margin: 4em auto;
  @media (max-width: 768px) {
    margin: 2em auto;
  }
}

.kitchen-centralized__container {
  display: flex;
  gap: 40px;
  width: 90%;
  margin-right: auto;
  font-size: 24px;
  @media (max-width: 1024px) {
    gap: 30px;
    width: 95%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
}

.kitchen-centralized__image-con {
  flex: 1;
  & > img {
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      height: auto;
    }
  }
}

.kitchen-centralized__text-con {
  flex: 1;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0;
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
}

.centralized-card__container {
  display: flex;
  gap: 20px;
}

.centralized-card__image-con {
  display: flex;
  gap: 10px;
  min-width: 60px;
}

.centralized-card__text-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h4 {
    font-size: 24px;
  }
  & > p {
    font-size: 16px;
    line-height: 140%;
  }
}
