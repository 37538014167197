.kitchenwaitinglist {
  background: url("../../assets/waitlistBackground.png");
  min-height: 100vh;
  color: white;
  display: flex;
  position: relative;
}

.kitchenwaitlistcontainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 3em 0 0;
  background-image: linear-gradient(
    to top,
    rgba(29, 29, 31, 0),
    rgba(29, 29, 31, 0.148),
    rgba(29, 29, 31, 0.397),
    rgba(29, 29, 31, 0.616),
    rgba(29, 29, 31, 0.553),
    rgba(29, 29, 31, 0.281),
    rgba(29, 29, 31, 0.16)
  );
  text-align: center;
}

.waitlist__image-con {
  max-width: 600px;
  position: relative;

  @media (max-width: 1200px) {
    max-width: 500px;
  }

  @media (max-width: 1024px) {
    max-width: 450px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  & > img {
    width: 100%;
    height: auto;
  }
}

.waitlist__image-con-mobile {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
  }
  & > img {
    width: 100%;
    height: auto;
  }
}

.waitlist__texticon-con {
  @media (max-width: 768px) {
    & > svg {
      width: 100px;
      height: 50px;
    }
  }
}

// .waitlist__texticon-con-mobile {
//   display: none;
//   @media (max-width: 768px) {
//     display: flex;
//     justify-content: center;

//   }
// }

.kitchenwaitlist__text-con {
  width: 100%;
  position: relative;
  top: -165px;
  background-image: linear-gradient(
    to top,
    rgba(29, 29, 31, 0.1),
    rgba(29, 29, 31, 0.233),
    rgba(29, 29, 31, 0.454),
    rgba(29, 29, 31, 0.516),
    rgba(29, 29, 31, 0.676),
    rgba(29, 29, 31, 0.865),
    rgba(29, 29, 31, 0.936),
    rgba(29, 29, 31, 0.877),
    rgba(29, 29, 31, 0.781),
    rgba(29, 29, 31, 0.066)
  );
  @media (max-width: 768px) {
    top: -180px;
    background-image: linear-gradient(
      to top,
      rgba(29, 29, 31, 0.1),
      rgba(29, 29, 31, 0.21),
      rgba(29, 29, 31, 0.381),
      rgba(29, 29, 31, 0.432),
      rgba(29, 29, 31, 0.676),
      rgba(29, 29, 31, 0.922),
      rgba(29, 29, 31, 0.893),
      rgba(29, 29, 31, 0.963),
      rgba(29, 29, 31, 0.941),
      rgba(29, 29, 31, 0.053)
    );
  }
}

.kitchenwaitlist__text-con > div {
  width: 60%;
  margin: auto;
  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
}

.waitlist__textcontent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.kitchen-waitlist__form-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: 2rem auto;
  width: 80%;
  @media (max-width: 576px) {
    width: 100%;
  }
  & > input {
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    flex: 1;
    width: 100%;
    &::placeholder {
      color: #233748;
      opacity: 0.51;
    }
  }
  & > button {
    background: #00bf6d;
    border-radius: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    color: white;
  }
}

.kitchenwaitlist__footer {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 3rem auto 0;
  justify-content: center;
}

.waitlist__socials {
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 576px) {
    gap: 10px;
  }
}
