#testimonialContainer {
  background-color: white;
  margin: 2em auto;
  width: 85%;
  max-width: 1440px;

  .testimonialList {
    display: flex;
    overflow-x: scroll;
    gap: 80px;
    padding: 1em 0.5em;

    &::-webkit-scrollbar {
      display: none;
    }

    .testimonialItem {
      text-align: center;
      background: #f3fcf7;
      box-shadow: 0px 4px 5px 3px rgba(0, 0, 0, 0.04);
      border-radius: 54px;
      flex: 1 0 450px;
      padding: 35px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 1024px) {
        flex: 1 0 400px;
      }

      @media screen and (max-width: 768px) {
        flex: 1 0 300px;
      }

      .testimonialItem__bottomText {
        width: 70%;
        margin: auto;

        .testimonialItem__name {
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #00bf6d;
        }
      }
    }
  }
}
