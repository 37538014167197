.home-hero__wrapper {
  background: url("../../../../public/images/hero_background.svg") 0 / cover
    no-repeat;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 7.5em 0 0;
}

.home-hero__container {
  display: flex;
  align-items: center;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}

.home-hero__text-con {
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 90%;
  }
  & > h1 {
    font-size: 50px;
    font-family: "Gilroy-Bold", sans-serif;

    @media (max-width: 1350px) {
      font-size: 46px;
    }
    @media (max-width: 1280px) {
      font-size: 42px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
    }
    @media (max-width: 890px) {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 44px;
    }
    @media (max-width: 576px) {
      font-size: 32px;
    }
  }
  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      gap: 10px;
      & img {
        width: 120px;
      }
    }
  }
}

.home-hero__image-con {
  flex: 5;
  position: relative;
  @media (max-width: 1280px) {
    flex: 4;
  }
  & > img {
    width: 100%;
    height: auto;
    position: relative;
    top: 20px;
  }
}
