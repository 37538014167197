.waitingListConfirmation {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    text-align: center;
    box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.11);
    border-radius: 25px;
    background: #fff;
    padding: 0 2rem 2rem;
    position: relative;
    width: 50%;
    margin: auto;
    max-width: 500px;

    @media (max-width: 576px) {
      padding: 0 1rem 2rem;
    }

    img {
      margin-top: -50px;
    }

    & > div {
      margin-top: 30px;
      a {
        text-decoration: none;
        & > button {
          font-size: 16px;
          color: white;
          cursor: pointer;
          padding: 15px ;
          border-radius: 8px;
          border: #00bf6d;
          background: #00bf6d;
        }
      }
    }

    h3 {
      color: #00bf6d;
      margin-bottom: 10px;
      font-size: 32px;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    p {
      color: #202022;
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    @media (max-width: 768px) {
      width: 70%;
    }

    @media (max-width: 576px) {
      width: 75%;
    }
  }
}

.waitlist__confirm-close-icon {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  & > svg {
    cursor: pointer;
  }
  @media (max-width: 576px) {
    padding: 2rem 1rem;
  }
}
