#blogPostHero{
    background-image: url('../../assets/BlogPostHeroImage.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;   
    min-height: 607px;

    @media screen and (max-width: 500px){
        min-height: 465px;
    }

}