

#blogPage{
    min-height: 100vh;
    background: white;

    .blogPost__comingSoon{
        img{
            // border: 3px solid red;
            max-width: 100%;
            height: auto;
        }

        // border: 1px solid red;
        text-align: center;
        padding-top: 7.5em;

        .blogPost__text{
            margin-top: 20px;
            h3{
                font-size: 51px;
                line-height: 60px;
                text-align: center;
                color: #00BF6D;
            }

            p{
                margin-top: 2px;
                
            }
        }
    }
}