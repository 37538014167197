.kitchen-empower__wrapper {
  background: #ffffff;
  color: #233748;
  position: relative;
  max-width: 1440px;
  margin: auto;
}

.kitchen-empower__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    gap: 20px;
    width: 90%;
  }
}

.kitchen-empower__top {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -300px;
  width: 85%;
  margin: auto;
  max-width: 1200px;
  @media (max-width: 1280px) {
    top: -250px;
  }
  @media (max-width: 1024px) {
    top: -200px;
  }
  @media (max-width: 768px) {
    top: -150px;
    width: 90%;
  }
  @media (max-width: 576px) {
    top: -100px;
  }
  @media (max-width: 425px) {
    top: -80px;
  }
}

.kitchen-empower__image-con {
  width: 95%;
  margin: auto;
  & > img {
    width: 100%;
    height: auto;
  }
}

.kitchen-empower__gradient {
 background: linear-gradient(
    to top,
    rgba(230, 230, 237, 0.461),
    rgba(230, 230, 237, 0.575),
    rgba(230, 230, 237, 0.929),
    rgba(230, 230, 237, 0.952)
  );
  padding: 70px 0;
  margin: 90px auto 0;
  width: 70%;
  @media (max-width: 1380px) {
    margin: 70px auto 0;
  }
  @media (max-width: 1280px) {
    margin: 30px auto 0;
  }
  @media (max-width: 1024px) {
    margin: 20px auto 0;
    padding: 45px 0;
  }
  @media (max-width: 880px) {
    margin: 10px auto 0;
    padding: 35px 0;
  }
  @media (max-width: 768px) {
    margin: 0px auto 0;
  }
  @media (max-width: 576px) {
    padding: 15px 0;
  }
}

.kitchen-empower__bottom {
  background: #ffffff;
  position: relative;
  z-index: 20;
  margin-bottom: 20px;
  padding: 60px 0 0;
  @media (max-width: 1024px) {
    padding: 60px 0 0;
  }
  @media (max-width: 880px) {
    padding: 30px 0 0;
  }
  @media (max-width: 768px) {
    padding: 10px 0 0;
    margin-bottom: 5px;
  }
}
