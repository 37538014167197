.home-subscribe__wrapper {
  background: url("../../../../public/images/hero_background.svg") 0 / cover
    no-repeat;
  color: white;
  position: relative;
}

.home-subscribe__cover {
  position: relative;
  max-width: 1440px;
  margin: auto;
}

.home-subscribe__container {
  display: flex;
  align-items: center;
  gap: 50px;
  width: 92.5%;
  max-width: 1440px;
  margin-left: auto;
  overflow: hidden;
  @media (max-width: 1280px) {
    gap: 30px;
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 95%;
    gap: 20px;
  }
}

.home-subscribe__text-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    flex: 1;
    width: 94%;
    margin: 0 auto 0 0;
  }
  & > h3 {
    font-size: 48px;
    @media (max-width: 1280px) {
      font-size: 42px;
    }
    @media (max-width: 1024px) {
      font-size: 38px;
    }
    @media (max-width: 900px) {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 42px;
    }
    @media (max-width: 576px) {
      font-size: 32px;
    }
  }
  & > p {
    font-size: 24px;
    @media (max-width: 1280px) {
      font-size: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      & > br {
        display: none;
      }
    }
  }
}

.home-subscribe__image-con {
  flex: 1;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.home-subscribe__image-con-mobile {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
    & > img {
      width: 480px;
      margin-left: auto;
    }
  }
  @media (max-width: 576px) {
    & > img {
      width: 420px;
    }
  }
  @media (max-width: 480px) {
    & > img {
      width: 360px;
    }
  }
  @media (max-width: 376px) {
    & > img {
      width: 310px;
    }
  }
}

.home-subscribe__form-con {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 1rem 0 0;
  width: 80%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  & > input {
    padding: 15px 20px;
    border-radius: 65px;
    outline: none;
    border: none;
    flex: 1;
    @media (max-width: 768px) {
      width: 100%;
    }
    &::placeholder {
      color: #233748;
      opacity: 0.51;
    }
  }
  & > button {
    background: #233748;
    border-radius: 65px;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    flex: 1;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
