

#blogArticle{
    margin-top: 65px;

    .blogArticle__heading{
        h1{
            font-size: 96px;
            line-height: 94px;

            
        @media screen and (max-width: 768px){
            font-size: 72px;
            line-height: 60px;
        }

        @media screen and (max-width:500px){
            font-size: 48px;
            line-height: 50px;
        }
        }

    }

    .blogArticle__time{
        margin-top: 11px;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        margin-bottom: 31px;

        .blogArticle__timeStamp{
            width: 103px;
            height: 43px;
            border: 1px solid #00BF6D;
            border-radius: 26px;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #00BF6D;
            margin-right: 10px;
        }

        .blogArticle__authorName{
            gap: 10px;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            color: #00BF6D;
        }
    }

    .blogArticle__main{
        width: 65%;
        
        font-weight: 400;

        @media screen and (max-width: 768px){
            width: 80%;
        }

        @media screen and (max-width:500px){
            width: 90%;
            // margin-inline: auto;
        }

        img{
            margin: 30px 0;
            max-width: 100%;
            height: auto;
        }

        .blogArticle__main1,
        .blogArticle__main2{
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }
        
        .blogArticle__main2 > p:first-of-type{
            margin-bottom: 30px;
        }

        .blogArticle__main2 > p:nth-child(2){
            margin-bottom: 30px;
        }
    }
}