*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App{
  min-height: 100vh;
  /* background:  #F3FCF7; */
}