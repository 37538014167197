#copyright {
  padding: 1em 0;
  font-weight: 400;
  max-width: 1280px;
  width: 85%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
  }
}
