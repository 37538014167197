@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.reportTable{
    // border: 3px solid purple;

    h2{
        margin-bottom: 25px;
    }

    .reportTable__tabs{
        // border: 1px solid red;
        border-bottom: 2px solid #AAACA6;

        .css-heg063-MuiTabs-flexContainer{
           button{
            font-size: 17px;
            font-weight: bold;
            // color: black;
           }

            button:focus{
                color: black !important;
            }
        }

        .css-h44lgv-MuiButtonBase-root-MuiTab-root.Mui-selected{
            color: black;
        }

        .css-1aquho2-MuiTabs-indicator{
            background-color: #00BF6D;
            height: 5px;
            border-radius: 3px;
        }
    }

    .report__search{
        margin: 20px;
        // border: 1px solid red;
        display: flex;
        gap: 7px;

        input{
            flex: 1;
            margin-right: auto;
            border: 1px solid #E2E3DD;
            border-radius: 8px;
            padding: 10px 0 10px 10px;
            outline: none;

            &::placeholder{
                color: #AAACA6;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
            }
        }

        button{
            // margin-right: auto;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #E2E3DD;
            border-radius: 8px;
            cursor: pointer;

            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #1F2A37;
            font-family: 'Inter', sans-serif;


            .reportTable__buttonIcons{
                margin-right: 8px;
            }
        }
    }

    .css-19kzrtu{
        padding-top: 0;
    }
}
