.home-unique__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4em 0 0;
}

.home-unique__container {
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.home-unique__text-con {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  & > h3 {
    font-size: 40px;
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  & > p {
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 20px;
      & > br {
        display: none;
      }
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.home-unique__images-con {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 2em auto;
  @media (max-width: 768px) {
    display: none;
  }
}

.home-unique__images-con-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: 2em auto;
  }
}

.home-unique__image-con {
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: auto;
  }
}

.home-unique__dish-image-con {
  @media (min-width: 1650px) {
    display: none;
  }
  display: block;
  position: absolute;
  left: 0;
  bottom: -120px;
  max-width: 400px;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1280px) {
    bottom: -150px;
    max-width: 350px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.home-unique__recipe-icon {
  margin: 4em auto 2em;
  text-align: center;
}
