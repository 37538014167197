

    // 
    // font-weight: 400;

#motto{
    // display: grid;
    margin: 80px 0;
    margin-inline: auto;
    background: white;
    // border: 1px solid red;
    border-radius: 20px;
    overflow: hidden;
    width: 80%;

    @media screen and (max-width: 500px){
        width: 90%;
    }
    
    
    .motto__container{
        // margin: auto;
        background-color: white;
        width: 100%;
        height:  319.69px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../assets/mottoBackground.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        // border: 1px solid red;

        @media screen and (max-width: 500px){
            padding: 54px 0px;
            height: 445px;
        }
    
        .motto__containerText{
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 500px){
                display: none;
            }


            h2{
                font-size: 48px;
                line-height: 93.3%;
                text-align: center;

                @media screen and (max-width: 500px){
                    font-size: 36px;
                }
            }

            p{
                margin: 6px 0px 20px 0px;
                
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #F3FCF7;
            }
        }

        .motto__containerTextMobile{
            display: none;

            @media screen and (max-width: 500px){
                display: flex;
                flex-direction: column;
                color: white;
                align-items: center;
                // padding: 54px 0px;

                & > h2{
                    font-size: 36px;
                    line-height: 34px;
                    text-align: center;
                }

                & > div {
                    
                    font-weight: 400;
                    margin-top: 5px;
                    margin-bottom: 30px;
                }                
            }
        }
    }
}