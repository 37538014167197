

#adminPortal{
    min-height: 100vh;
    background-color: white;
    display: grid;
    place-items: center;

    form{
        min-width: 454px;
        // height: 430.7px;
        background: #FFFFFF;
        box-shadow: 4px 4px 39px 6px rgba(0, 0, 0, 0.11);
        border-radius: 12px;

        .adminPortal__header{
            text-align: center;
            // border: 1px solid red;
            margin: 23px 0px 31px;

            img{
                width: 100.4px;
                height: 40px;
            }

            h3{
                font-size: 24px;
                line-height: 28px;
                color: #233748;
                margin-top: 10px;
            }
        }

        .adminPortal__fields{
            // border: 1px solid red;
            margin-bottom: 30px;

            & > input{
                display: block;
                width: 85%;
                padding: 13px 20px;
                margin-inline: auto;
                border-radius: 8px;
                border:1px solid #D1D5DB;
            

                &:first-child{
                    // border: 1px solid red;
                    margin-bottom: 30px;
                }

                &::placeholder{
                    
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    color: #6B7280;
                }
            }
        }

        .adminPortal__button{
            display: flex;
            justify-content: center;
            // border: 1px solid red;
            margin-inline: auto;
            background: #00BF6D;
            border-radius: 6px;
            width: 85%;
            padding: 6.5px 0;
            font-size: 20px;
            line-height: 23px;
            color: #FFFFFF;
            border: none;
            cursor: pointer;
            margin-bottom: 19px;
        }

        .adminPortal__forgotPassword{
            font-size: 14px;
            line-height: 16px;
            // color: #00BF6D !important;
            margin-left: 35.5px;
            margin-bottom: 33px;
        }
    }

    .adminPortal__footer{
        position: fixed;
        bottom: 0;
    }
}