.flaggedCommunities{
    // border: 1px solid red;
    
    .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:hover{
        background:  rgba(0, 191, 109, 0.37);
    }
}

.flaggedCommunities__Popover{
    // background-color: red;
    padding: 8px 1rem;

    .popover__icons{
        margin-right: 8px;
    }

    .popover__delIcon{
        color: #F05252;
    }

    span{
        font-size: 14px;
    }
}