.contact__confirmation{
    position: relative;
    margin-inline: auto;
    background: #FFFFFF;
    box-shadow: 4px 4px 39px 6px rgba(0, 0, 0, 0.11);
    border-radius: 31px;
    width: 493px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 500px){
        max-width: 95%;
    }

    img{
        margin-bottom: 1rem;
    }

    .contact__text{
        h1{
            font-size: 32px;
            line-height: 38px;
            color: #233748;
        }

        @media screen and (max-width: 500px){
            div{
                width: 90%;
                margin: 0 auto;
            }
        }

        div > p{
            font-size: 24px;
            line-height: 28px;
            color: #233748;
        }
    }
}