.kitchen-hero__wrapper {
  background: url("../../../../public/images/home_capture_background.svg") 0 /
    cover no-repeat;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 7.5em 0 20em;
  @media (max-width: 1280px) {
    padding-bottom: 17em;
  }
  @media (max-width: 1024px) {
    padding-bottom: 15em;
  }
  @media (max-width: 768px) {
    padding-bottom: 12em;
  }
  @media (max-width: 576px) {
    padding-bottom: 8em;
  }
  @media (max-width: 425px) {
    padding-bottom: 6em;
  }
}

.kitchen-hero__container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
}

.kitchen-hero__left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h3 {
    font-size: 48px;
    font-family: "Gilroy-Bold", sans-serif;
    @media (max-width: 1350px) {
      font-size: 46px;
    }
    @media (max-width: 1280px) {
      font-size: 42px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
    }
    @media (max-width: 768px) {
      font-size: 42px;
    }
    @media (max-width: 576px) {
      font-size: 36px;
    }
  }
}

.kitchen-hero__right {
  flex: 1;
  padding-left: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 0;
  }
}
