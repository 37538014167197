.faq-details__wrapper {
  width: 80%;
  max-width: 1280px;
  margin: 3em auto;
  @media (max-width: 576px) {
    width: 90%;
  }
}

.faq__container {
  .faq__answers {
    font-weight: 400;
  }
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  border: none !important;
  border-left: none !important;
  border-right: none !important;
}
