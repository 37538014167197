@keyframes scroll-right {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(calc(-250px * 2));
  }
}

.slide {
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
}

.slider-container {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  background: transparent;
}

.slide-track-right {
  animation: scroll-right 15s linear infinite;
  display: flex;
  gap: 30px;
  width: max-content;
}

.slide-track-right:hover {
  animation-play-state: paused;
}

.scroller-card__button {
  background: #ffffff;
  border-radius: 65px;
  padding: 8px 15px;
  border: 1px solid #233748;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  font-size: 15px;
  font-family: "Gilroy-Bold", sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    padding: 5px 10px;
  }
}
