.skibble-home {
  color: #233748;
  background: white;
}

.skibble-home-nav-con {
  position: fixed;
  top: 3em;
  background: white;
  z-index: 3000;
  width: 90%;
  max-width: 1440px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  border: 1px solid rgba(217, 217, 217, 0.62);
}

.skibble-home-unique-con {
  background: #f5fafe;
}

.skibble-home-width {
  max-width: 1400px;
  width: 85%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
  }
}
