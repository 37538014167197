.home-business__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4em 0 0;
  background: url("../../../../public/images/home_capture_background.svg") 0 /
    cover no-repeat;
  color: white;
}

.home-business__container {
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
}

.home-business__text-con {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  @media (max-width: 576px) {
    width: 90%;
    margin: auto;
  }
  & > svg {
    margin: 0 auto 1em;
  }
  & > a {
    & > svg {
      cursor: pointer;
      @media (max-width: 768px) {
        height: 70px;
        width: 260px;
      }
    }
  }
  & > div {
    margin-bottom: 0.5em;
  }
  & > h3 {
    font-size: 40px;
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  & p {
    font-size: 24px;
    @media (max-width: 1280px) {
      & > br {
        display: none;
      }
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.home-business__image-con {
  display: flex;
  justify-content: center;
  margin: 2em auto 0;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.home-business__image-con-mobile {
  display: none;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 576px) {
    display: flex;
    margin: 2em auto 0;
  }
}
