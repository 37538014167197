@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./fonts/FontsFree-Net-Gilroy-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #133924;
  /* remove the above line for the landing page */
}

h3 {
  font-family: "Gilroy-Bold", sans-serif;
}

button:disabled {
  opacity: 0.5;
}

.error-text {
  color: red;
  text-align: center;
  margin: 1em auto;
}