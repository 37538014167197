#contact {
  min-height: 100vh;
  background: white;

  .contact__image {
    background-image: url("../../assets/contactImage.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // min-height: 1165px;

    .contact-wrapper {
      max-width: 1400px;
      width: 85%;
      margin: 0 auto;
    }

    .contact__header {
      padding-top: 50px;

      img {
        cursor: pointer;
      }
    }

    .contact__container {
      display: flex;
      gap: 30px;
      padding: 3em 0;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .contact__left {
        flex: 5.5;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        & > div:first-of-type {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
        & h3 {
          font-size: 40px;
          @media (max-width: 1280px) {
            font-size: 36px;
          }
          @media (max-width: 1024px) {
            font-size: 26px;
          }
          @media (max-width: 768px) {
            font-size: 30px;
          }
          @media (max-width: 576px) {
            font-size: 24px;
          }
        }
        & h4 {
          font-size: 26px;
          @media (max-width: 768px) {
            font-size: 22px;
          }
          @media (max-width: 576px) {
            font-size: 18px;
          }
        }
        & p {
          font-size: 22px;
          @media (max-width: 768px) {
            font-size: 18px;
          }
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }
      }

      .contact__left-bottom {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > div {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }

      .contact__mobile-bottom {
        display: none;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: white;

          & > div {
            display: flex;
            flex-direction: column;
            gap: 3px;
          }
        }
      }

      .contact__right {
        flex: 4.5;

        #form {
          background: white;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 24px 20px;
          box-shadow: 4px 4px 39px 6px rgba(0, 0, 0, 0.11);
          border-radius: 14px;
          @media (max-width: 768px) {
            padding: 20px 16px;
            border-radius: 12px;
            gap: 12px;
          }
          @media (max-width: 576px) {
            padding: 18px 14px;
          }

          button {
            margin-top: 10px;
            background: #00bf6d;
            border-radius: 10px;
            padding: 20px 0px;
            font-size: 20px;

            color: #ffffff;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
