.iconWhite{
    color: white;
}

.iconGreen{
    color: red;
}

.nav__drawerLists{
    background: #00BF6D;

    .nav__drawerText{
        font-size: 36px;
        line-height: 42px;
        padding: 15px 0px;
        color: #FFFFFF;
        border-bottom: 1px solid white;
    }
}