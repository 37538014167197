#faq {
  margin-bottom: 3em;

  .faq__heading {
    font-size: 44px;
    text-align: center;
    color: #00bf6d;
    margin-bottom: 0.5em;
    font-family: "Gilroy-Bold", sans-serif;

    @media (max-width: 1280px) {
      font-size: 36px;
    }
    @media (max-width: 1024px) {
      font-size: 26px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
    }
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }

  .faq__container {
    .faq__answers {
      font-weight: 400;
    }
  }

  .faq__see-more {
    text-align: center;
    margin: 2em auto;
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
    a {
      color: #00bf6d;
      text-decoration: none;
    }
  }

  .MuiAccordion-root {
    box-shadow: none !important;
  }

  .MuiAccordionSummary-root:first-of-type {
    border-top: none !important;
  }

  .MuiAccordionSummary-root.Mui-expanded:first-of-type {
    border-top: 1px solid lightgray !important;
  }

  .MuiAccordionSummary-root {
    border: 1px solid lightgray !important;
    border-left: none !important;
    border-right: none !important;
  }
}
