.kitchen-simplify__wrapper {
  background: #ffffff;
  color: #233748;
  position: relative;
  max-width: 1440px;
  margin: 1em auto;
}

.kitchen-simplify__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  @media (max-width: 768px) {
    gap: 20px;
    width: 90%;
    font-size: 16px;
    line-height: unset;
  }
}
