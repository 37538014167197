#footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  gap: 10px;
  padding: 2em 0;
  max-width: 1400px;
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .footer__one {
    & > img {
      width: 165px;
      height: 65px;
      object-fit: contain;
      margin-bottom: 22.24px;
    }

    .footer__skibbleDescription {
      font-size: 16px;
      line-height: 19px;
      color: #233748;
      margin-bottom: 10px;

      .footer__motto {
        margin-top: 20px;
        font-weight: bold;
      }
    }

    .footer__socialLinks {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 25%;
      // border: 1px solid red;

      @media screen and (max-width: 1200px) {
        // margin-inline: auto;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        &:first-child {
          margin-left: 0px;
        }
        cursor: pointer;
        object-fit: contain;
        // margin-right: 10px;
        margin: 0px 10px;
      }
    }
  }

  .footer__two,
  .footer__three {
    padding-top: 20px;
    font-weight: 400;

    h2 {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 28px;
      color: #233748;
    }

    div > p {
      margin-bottom: 1rem;

      a {
        text-decoration: none;
        color: #233748;
      }
    }

    .footerLink_p {
      transition: all 0.5s ease;

      &:hover {
        // color: #00BF6D;
        text-decoration: underline;
      }

      &:focus {
        color: #00bf6d;
      }
    }
  }

  .footer__four {
    padding-top: 20px;
    h2 {
      margin-bottom: 38px;
      font-size: 24px;
      line-height: 28px;
      color: #233748;
    }

    & > .footer__fourText {
      margin-bottom: 10px;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #233748;
      }
    }

    .footer__fourInput {
      input {
        background: #f0f1ec;
        border-radius: 48px;
        height: 50px;
        width: 205px;
        border: none;
        outline: none;
        padding: 10px 20px;
        margin-right: 5px;
      }

      button {
        border: none;
        outline: none;
        cursor: pointer;
        padding: 13px 23px;
        background: #00bf6d;
        border-radius: 68.7697px;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;

        div {
          display: flex;
          align-items: center;

          span {
            margin-right: 5px;
          }
        }
        // display: flex;
      }

      @media screen and (max-width: 500px) {
        input,
        button {
          width: 100%;
        }

        input {
          margin-bottom: 15px;
        }

        button {
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }
    }

    .footer__socialLinksMoblie {
      display: none;

      @media screen and (max-width: 500px) {
        display: flex;
        // border: 1px solid red;
        width: 50%;
        justify-content: space-between;
        margin-top: 25px;
        align-items: center;
      }
    }
  }
}

.footer__form-con {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 1rem 0 0;
  width: 80%;
  max-width: 480px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  & > input {
    padding: 15px 20px;
    border-radius: 65px;
    outline: none;
    border: none;
    flex: 1;
    background: #f0f1ec;
    @media (max-width: 768px) {
      width: 100%;
    }
    &::placeholder {
      color: #233748;
      opacity: 0.51;
    }
  }
  & > button {
    background: #00bf6d;
    border-radius: 65px;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    flex: 1;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
