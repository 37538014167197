@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.adminDashboard{
    min-height: 100vh;
    background-color: #F1F1F1;
    font-family: 'Inter', sans-serif;

    // border: 3px solid purple;

    .adminDashboard__container{
        // border: 1px solid red;
        height: 600px;
        display: flex;
        margin-left: 30px;
        margin-right: 20px;
        margin-top: 25px;
        gap: 20px;

        .adminDashboard__sidebar{
            // border: 1px solid green;
            height: 100%;
            flex: 0.2;

            .adminDashboard__sidebarButton{
                display: flex;
                // justify-content: space-between;
                align-items: center;
                padding: 20px 14px;
                width: 100%;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                background: transparent;

                &:hover,
                &:focus{
                    background: #A1E7C9;
                }

                p{
                    margin-left: 10px;
                    font-size: 17px;
                    line-height: 20px;
                    color: #233748;
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                }

                .adminDashboard__noOfReports{
                    margin-left: auto;
                }
            }

            & > * {
                margin-bottom: 10px;
            }

        }

        .adminDashboard__main{
            flex: 1;
            // border: 2px solid purple;
        }
    }
}