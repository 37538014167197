

#termsPage{
    min-height: 100vh;
    background-color: white;
    display: flex;
    gap: 5px;
    scroll-behavior: smooth;
    
    font-weight: 400;
    // padding-top: 130px;

    @media screen and (max-width: 768px){
        display: block;
    }

    .term__drawer{
        display: none;

        @media screen and (max-width: 768px){
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding: 55px 20px 49.2px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 55px;
            margin-bottom: 49.2px;
            // border: 1px solid orange;

            img{
                width: 97.5px;
                height: 36.18px;
                object-fit: contain;
            }
        }
    }

    .term__mobileHeader{
        display: none;

        @media screen and (max-width: 768px){
            // border: 1px solid red;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            padding: 0px 25px;

            h1{
                margin-bottom: 7px;
            }
            p{
                font-weight: 400;
                font-size: 15px;
                line-height: 121.45%;
                color: #6B7280;
            }
        }
    }

    .term__sidebar{
        position: sticky;
        top: 0;
        padding-top: 96px;
        padding-left: 126px;
        padding-right: 18px;
        overflow-y: scroll;
        height: 100vh;

        @media screen and (max-width: 1000px) {
            padding-left: 63px;
        }

        @media screen and (max-width: 768px){
            display: none;
            // border: 1px solid red;
        }

        img{
            width: 138px;
            height: 54.8px;
            cursor: pointer;
        }

        .term__sidebar__heading{
            margin: 49.2px 0 7px 0;
            font-size: 32px;
            line-height: 38px;
            color: #233748;
        }

        .term__sidebar__lastUpdated{
            font-weight: 400;
            font-size: 15px;
            line-height: 121.45%;
            color: #6B7280;
            margin-bottom: 40px;
        }

        .term__sidebarLists{
            // border: 1px solid red;
            display: flex;
            flex-direction: column;

            .term__sidebarList{
                width: 280px;
                height: 70px;
                padding: 14px 32px 14px 14px;
                cursor: pointer;
                color: #233748;
                font-size: 17px;
                font-weight: bold;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover{
                    background: rgba(0, 191, 109, 0.37);
                    border-radius: 5px;
                }

                &:focus{
                    background:rgba(0, 191, 109, 0.37) ;
                }

            }

        }

        &::-webkit-scrollbar {
            width: 10px;
            // height: 361px;
            margin: 0 10px;
        }
    
        &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 32px;
        margin: 0 10px;

        }

        &::-webkit-scrollbar-track {
           
          }
    }

   .termsPage__container{
        padding-left: 35px;
        padding-right: 135px;
        padding-top: 200px;
        flex: 1;
        // border-left: 1px solid #7C7C7C;
        border-left: 1px solid lightgray;
        scroll-behavior: smooth;
        padding-bottom: 70vh;

        @media screen and (max-width: 1000px) {
            padding-right: 67.5px;
            padding-bottom: 50vh;
        }

        @media screen and (max-width: 768px){
            // border: 1px solid red;
            padding-top: 0px;
            padding-left: 25px;
            padding-right: 25px;
        }

        *{
            margin-bottom: 20px;
        }

        h1{
            font-size: 28px;
            line-height: 33px;
            color: #233748;
        }
        h2{
            // font-family: 'Gilroy-Bold';
            font-size: 17px;
            line-height: 20px;
            color: #233748;
        }

        a{
            color: #00BF6D;
        }

        p{
            font-size: 15px;
            // font-family: 'Questrial';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 121.45%;
            color: #233748;

            strong{
                text-decoration: underline;
            }
        }

        ul{
            margin-left: 30px;
        }

        li{
            // font-family: 'Questrial';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 121.45%;
            color: #233748;
            margin-bottom: 0px;
        }

        .icon__scrollToTop{
            // position: fixed;
            position: sticky;
            // margin-top: 100px;
            top: 50vh;
            right: 0;
        }
    }
}