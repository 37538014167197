.home-discover__wrapper {
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2em 0 0;
}

.home-discover__container {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  @media (max-width: 1280px) {
    gap: 20px;
  }
  @media (max-width: 1024px) {
    gap: 15px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
}

.home-discover__text-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  & > h3 {
    font-size: 44px;
    font-family: "Gilroy-Bold", sans-serif;

    @media (max-width: 1280px) {
      font-size: 36px;
    }
    @media (max-width: 1024px) {
      font-size: 26px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      & > br {
        display: none;
      }
    }
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  & > p {
    font-size: 24px;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      gap: 10px;
      & img {
        width: 120px;
      }
    }
  }
}

.home-discover__image-con {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.home-discover__image-con-mobile {
  display: none;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    display: flex;
  }
}
