#blogPost{
    margin-top: 30px;
    background: #F3FCF7;
    display: grid;

    .blogPost__container{
        width: 70%;
        // border: 1px solid red;
        margin: 50px auto;
        // padding: 20px 0px;

        h1{
            font-size: 64px;
            line-height: 75px;
            text-align: center;
            color: #00BF6D;
            margin-bottom: 30px;

            @media screen and (max-width: 500px){
                font-size: xx-large;
            }
        }

        h3{
            text-align: center;
            font-size: 39.34px;
            line-height: 46px;
            color: #00BF6D;
            margin-bottom: 36px;
        }

        .blogPost__items{
            display: flex;
            gap: 20px;
            // border: 2px solid purple;
            flex-wrap: wrap;

            
            @media screen and (max-width: 768px){
                flex-direction: column;
            }

            .blogPost__item{
                // border: 3px solid purple;
                border: 1px solid lightgray;
                border-radius: 20px;
                background: white;
                flex: 1;
                width: 100%;


                .blogPost__itemImage > img{
                    width: 100%;
                }

                .blogPost__text{
                    background: white;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    margin-top: -30px;
                    padding: 15px;

                    h3{
                        font-size: 21.9412px;
                        line-height: 26px;
                        color: #00BF6D;
                    }

                    p{
                        font-size: 14.2618px;
                        line-height: 17px;
                        color: #233748;
                        margin-top: 5px;
                    }

                    button{
                        display: flex;
                        margin-left: auto;
                        color: black;
                        width: fit-content;
                        // border: 1px solid red;
                        margin-top: 3px;
                        font-size: 13.1647px;
                        line-height: 15px;
                        color: rgba(35, 55, 72, 0.8);
                        border: none;
                        outline: none;
                    }
                }
            }

           
        }
    }
}