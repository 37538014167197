.meets__wrapper {
  background: #ffffff;
  color: #233748;
  padding: 4em 0;
}

.meets__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    gap: 20px;
    width: 90%;
  }
}

.meets__top {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.meets__top-left {
  flex: 1;
  & > h3 {
    font-size: 50px;
    font-family: "Gilroy-Bold", sans-serif;
    @media (max-width: 1350px) {
      font-size: 46px;
    }
    @media (max-width: 1280px) {
      font-size: 42px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
    }

    @media (max-width: 768px) {
      font-size: 32px;
    }
    & > span {
      color: #00bf6d;
    }
  }
}

.meets__top-right {
  flex: 1;
  padding-left: 20px;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 0;
  }
}

.meets__bottom {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.meets__bottom-left {
  flex: 1;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
}

.meets__bottom-right {
  flex: 1;
  & h3 {
    font-size: 28px;
    font-weight: 600;
    color: #233748 !important;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  & p {
    font-size: 20px;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.MuiAccordion-root {
  box-shadow: none !important;
  border: none !important;
}

.MuiAccordionSummary-root {
  border: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0px 6px !important;
}

.MuiAccordion-root.Mui-disabled {
  background: transparent !important;
  border: none !important;
  color: #233748 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #233748 !important;
}

.MuiAccordionDetails-root {
  padding: 16px 6px 0 !important;
}

.MuiAccordionSummary-root.Mui-disabled {
  opacity: 1 !important;
}
