.about-motto__wrapper {
  background: #ffffff;
  padding: 4em 0;
  @media (max-width: 576px) {
    padding: 2em 0;
  }
}

.about-motto__container {
  display: flex;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  background: url("../../../../public/images/about_motto.svg") 0 / cover
    no-repeat;
  border-radius: 20px;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.about-motto__container-mobile {
  display: none;
  @media (max-width: 576px) {
    display: flex;
    width: 90%;
    margin: auto;
    background: url("../../../../public/images/about_motto.svg") 0 / cover
      no-repeat;
    border-radius: 20px;
  }
}

.about-motto__text-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 2em 0;
  margin: auto;
  & > h3 {
    font-size: 36px;
    color: #fff;
    @media (max-width: 1280px) {
      font-size: 30px;
    }
    @media (max-width: 1024px) {
      font-size: 26px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  & > p {
    font-size: 24px;
    color: #f3fcf7;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      & img {
        width: 120px;
      }
    }
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.about-motto__text-con-mobile {
  display: none;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 2em 10px;
    margin: auto;
    text-align: center;
    & > h3 {
      font-size: 24px;
      color: #fff;
    }
    & > p {
      color: #f3fcf7;
    }
    & > div {
      display: flex;
      gap: 20px;
      align-items: center;
      @media (max-width: 576px) {
        gap: 10px;
        & img {
          width: 120px;
        }
      }
    }
  }
}
