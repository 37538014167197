#notFound{
    min-height: 100vh;
    background: white;
    padding-top: 120px;
    color: black;

    .notFound__container{
        // border: 1px solid red;
        text-align: center;
        margin-top: 85px;
        margin-bottom: 100px;

        img{
            margin-bottom: 45px;
            height: auto;
            max-width: 100%;
        }

        h1{
            font-size: 96px;
            line-height: 94px;
            color: #00BF6D;
            margin-bottom: 11px;
        }

        p{
            font-size: 24px;
            line-height: 28px;
            color: #233748;
        }
    }
}