.home-recipe__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.home-recipe__container {
  width: 85%;
  max-width: 1280px;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.home-recipe__text-con {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  & > h3 {
    font-size: 40px;
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  & > p {
    font-size: 24px;
    margin-bottom: 0.5em;
    @media (max-width: 768px) {
      font-size: 20px;
      & > br {
        display: none;
      }
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 576px) {
      gap: 10px;
      & img {
        width: 120px;
      }
    }
  }
}

.home-recipe__image-con {
  display: flex;
  justify-content: center;
  margin: 3em auto 0;
  & > img {
    width: 100%;
    height: auto;
    max-width: 800px;
  }
}
