#aboutPage {
  min-height: 100vh;
  background: white;
}

.skibble-about-nav-con {
  position: fixed;
  top: 3em;
  background: white;
  z-index: 3000;
  width: 90%;
  max-width: 1440px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
}
