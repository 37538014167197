.home-capture__wrapper {
  background: url("../../../../public/images/home_capture_background.svg") 0 /
    cover no-repeat;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 4em 0 0;
}

.home-capture__container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 85%;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
}

.home-capture__text-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  & > h3 {
    font-size: 44px;
    @media (max-width: 1280px) {
      font-size: 36px;
    }
    @media (max-width: 1024px) {
      font-size: 26px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      & > br {
        display: none;
      }
    }
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  & > p {
    font-size: 24px;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & > div {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 576px) {
      gap: 10px;
      & img {
        width: 120px;
      }
    }
  }
}

.home-capture__image-con {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: auto;
    max-width: 350px;
  }
}
