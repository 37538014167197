@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.adminNav{
    // border: 1px solid red;
    padding: 1rem;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    background: white;

    .adminNav__left{
        display: flex;
        align-items: center;

        &:has(h1){
            h1{
                margin-left: 30px;
            }
        }
        
        img{
            width: 100px;
            height: 40px;
        }
    }

    .adminNav__right{
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;

        & > *:not(:first-child){
            margin-left: 30px;
        }
    }
}